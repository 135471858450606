"use client";
import React from "react";
import { useEffect, useState, useRef } from "react";
import SkillCard from "./SkillCard";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import pythonLogo from "../assets/python.png";
import swiftLogo from "../assets/swift.png";
import rLogo from "../assets/r.png";
import jsLogo from "../assets/js2.png";
import reactLogo from "../assets/react.png";
import dockerLogo from "../assets/docker.png";
import awsLogo from "../assets/aws.png";
import aiLogo from "../assets/ai.png";

export function Skills() {
  const [skills, setSkills] = useState([]);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.1 });
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });

  const y1 = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  useEffect(() => {
    fetch("/data/skillsData.json")
      .then(response => response.json())
      .then(data => setSkills(data))
      .catch(error => console.error("Error loading skills data:", error));
  }, []);

  const skillIcons = [
    { logo: pythonLogo, alt: "Python", category: "Languages" },
    { logo: swiftLogo, alt: "Swift", category: "Languages" },
    { logo: rLogo, alt: "R", category: "Languages" },
    { logo: jsLogo, alt: "JavaScript", category: "Languages" },
    { logo: reactLogo, alt: "React", category: "Frameworks" },
    { logo: dockerLogo, alt: "Docker", category: "DevOps" },
    { logo: awsLogo, alt: "AWS", category: "Cloud" },
    { logo: aiLogo, alt: "AI", category: "Machine Learning" },
  ];

  return (
    <section
      id="skills"
      ref={sectionRef}
      className="relative w-full py-4 overflow-hidden"
    >

      <div className="container relative w-full px-4 mx-auto">
        {/* Section header */}
        <motion.div
          className="w-full max-w-3xl mx-auto mb-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
        >
          <h2 className="mb-4 text-3xl font-bold text-white sm:text-4xl md:text-7xl lg:text-4xl">
            Skills & Technologies
          </h2>
          <p className="text-xl text-transparent sm:text-xl md:text-4xl lg:text-lg bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400">
          My skills and expertise in a look
          </p>
        </motion.div>

        <div className="grid w-full gap-8 mx-auto md:grid-cols-1 lg:grid-cols-3">
          <motion.div
            className="p-6 lg:col-span-3"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ delay: 0.2 }}
          >
            <div className="grid w-full grid-cols-4 gap-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-8">
              {skillIcons.map((skill, index) => (
                <motion.div
                  key={skill.alt}
                  className="flex flex-col items-center justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={isInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ delay: 0.1 + index * 0.05 }}
                  whileHover={{ y: -5, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-center justify-center mb-2 overflow-hidden transition-all duration-300 w-36 h-36 bg-white/10 rounded-xl hover:bg-white/20 group">
                    <img
                      src={skill.logo}
                      alt={skill.alt}
                      className="object-contain w-24 h-24 transition-transform duration-300 sm:w-24 sm:h-24 lg:w-12 lg:h-12 group-hover:scale-110"
                    />
                  </div>
                  <span className="text-xl font-semibold text-center text-gray-400 sm:text-xl lg:text-xs group-hover:text-white">{skill.alt}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>

        </div>
        <motion.div
          className="grid gap-6 mt-8 sm:grid-cols-1 lg:grid-cols-3"
          initial={{ opacity: 0 }}
          animate={isInView ? { opacity: 1 } : {}}
          transition={{ delay: 0.4 }}
        >
          {skills.map((skillSet, index) => (
            <motion.div
              key={index}
              className="p-6 border rounded-2xl bg-gradient-to-br from-gray-800/30 to-gray-900/30 border-white/5 backdrop-blur-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: 0.4 + index * 0.1 }}
              whileHover={{
                y: -5,
                boxShadow: "0 10px 30px -15px rgba(0, 0, 0, 0.5)",
                transition: { duration: 0.2 }
              }}
            >
              <SkillCard
                title={skillSet.title}
                skills={skillSet.skills}
                icon={skillSet.icon}
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default Skills;