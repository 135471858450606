import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

// ScrollReveal component that animates children when they come into view
export const ScrollReveal = ({ 
  children, 
  threshold = 0.1,
  delay = 0,
  duration = 0.5,
  direction = 'up', // 'up', 'down', 'left', 'right'
  distance = 50,
  once = true,
  className = '',
  ...props 
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once, threshold });
  const controls = useAnimation();
  
  // Determine initial position based on direction
  const getInitialPosition = () => {
    switch (direction) {
      case 'up': return { opacity: 0, y: distance };
      case 'down': return { opacity: 0, y: -distance };
      case 'left': return { opacity: 0, x: distance };
      case 'right': return { opacity: 0, x: -distance };
      default: return { opacity: 0, y: distance };
    }
  };
  
  // Determine final position
  const getFinalPosition = () => {
    return { 
      opacity: 1, 
      y: direction === 'up' || direction === 'down' ? 0 : undefined,
      x: direction === 'left' || direction === 'right' ? 0 : undefined
    };
  };

  useEffect(() => {
    if (isInView) {
      controls.start(getFinalPosition());
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial={getInitialPosition()}
      animate={controls}
      transition={{ 
        duration, 
        delay,
        ease: [0.25, 0.1, 0.25, 1] // cubic-bezier easing for a nice feel
      }}
      className={className}
      {...props}
    >
      {children}
    </motion.div>
  );
};

// Staggered children reveal component
export const StaggeredReveal = ({ 
  children, 
  threshold = 0.1,
  staggerDelay = 0.1,
  containerDelay = 0,
  duration = 0.5,
  direction = 'up',
  distance = 50,
  once = true,
  className = '',
  ...props 
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once, threshold });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  // Clone children and add staggered animation
  const staggeredChildren = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) return child;
    
    return (
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: direction === 'up' ? distance : direction === 'down' ? -distance : 0,
            x: direction === 'left' ? distance : direction === 'right' ? -distance : 0,
          },
          visible: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
              duration,
              delay: containerDelay + (index * staggerDelay),
              ease: [0.25, 0.1, 0.25, 1]
            }
          }
        }}
      >
        {child}
      </motion.div>
    );
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      className={className}
      {...props}
    >
      {staggeredChildren}
    </motion.div>
  );
};

export default ScrollReveal; 