import React, { Suspense, lazy } from 'react';
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import "./style.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import GridBackground from "./components/Grid";
import { ScrollReveal, StaggeredReveal } from './components/ScrollReveal';

// Lazy load components that are not immediately visible
const Projects = lazy(() => import('./components/Projects'));
const Certificates = lazy(() => import('./components/Certificates'));
const Profiles = lazy(() => import('./components/Profiles'));
const ContactMe = lazy(() => import('./components/ContactMe'));

export default function App() {
    return (
        <div className="w-full mx-auto overflow-x-hidden overflow-y-hidden bg-slate-900">
            <Navbar />
            <header id="home" className="relative w-full h-screen">
                <GridBackground />
                <ScrollReveal>
                    <Header />
                </ScrollReveal>
            </header>

            <main className="relative z-10">
                <section id="about" className="">
                    <ScrollReveal>
                        <AboutMe />
                    </ScrollReveal>
                </section>

                <section id="skills" className="py-20 bg-slate-900/50">
                    <StaggeredReveal staggerDelay={0.1}>
                        <Skills />
                    </StaggeredReveal>
                </section>

                <section id="projects" className="">
                    <StaggeredReveal staggerDelay={0.15}>
                        <Projects />
                    </StaggeredReveal>
                </section>

                <section id="certificates" className="">
                    <StaggeredReveal staggerDelay={0.1}>
                        <Certificates />
                    </StaggeredReveal>
                </section>

                <section id="profiles" className="">
                    <ScrollReveal>
                        <Profiles />
                    </ScrollReveal>
                </section>

                <section id="contact" className="">
                    <ScrollReveal direction="up">
                        <ContactMe />
                    </ScrollReveal>
                </section>
            </main>

            <Footer />
        </div>  
    )
}
