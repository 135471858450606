import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Add smooth scrolling behavior using inline styles instead of styled-components
const style = document.createElement('style');
style.textContent = `
  html {
    scroll-behavior: smooth;
  }
  
  body {
    overflow-x: hidden;
  }
`;
document.head.appendChild(style);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

