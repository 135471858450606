import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

export function AboutMe() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.2 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 20,
                stiffness: 100,
            },
        },
    };

    return (
        <div 
            id="about"
            className="relative w-full px-4 py-4 mx-auto bg-slate-900 sm:px-6 md:px-2 lg:px-8"
        >
            <motion.div 
                className="relative flex flex-col items-center justify-center w-full max-w-4xl mx-auto"
                ref={ref}
                variants={containerVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
            >
                <motion.h2
                    variants={itemVariants}
                    className="text-3xl font-bold text-white sm:text-4xl md:text-7xl lg:text-4xl"
                >
                    About Me
                </motion.h2>
                
                <motion.p 
                    variants={itemVariants}
                    className="mt-2 text-lg font-normal text-transparent sm:text-xl md:text-4xl lg:text-lg bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400"
                >
                    Get to know me
                </motion.p>
                
                <motion.div
                    variants={itemVariants}
                    className="mt-6 space-y-4 sm:mt-8 md:mt-10"
                >
                    <motion.p 
                        className="text-base font-light leading-relaxed tracking-wide text-center text-gray-200 sm:text-lg md:text-3xl/10 lg:text-lg"
                        initial={{ opacity: 0, y: 10 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.3, duration: 0.5 }}
                    >
                        Hi there! I'm Yahya Momtaz, I started my academic journey with a passion for building software, reflected in my bachelor's degree in Software Engineering.
                    </motion.p>
                    
                    <motion.p 
                        className="text-base font-light leading-relaxed tracking-wide text-center text-gray-200 sm:text-lg md:text-3xl/10 lg:text-lg"
                        initial={{ opacity: 0, y: 10 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.4, duration: 0.5 }}
                    >
                        However, I became interested in the fascinating field of data science during my Master's program because of my curiosity about data and how it could bring out fresh perspectives.
                    </motion.p>
                    
                    <motion.p 
                        className="text-base font-light leading-relaxed tracking-wide text-center text-gray-200 sm:text-lg md:text-3xl/10 lg:text-lg"
                        initial={{ opacity: 0, y: 10 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.5, duration: 0.5 }}
                    >
                        My perspective on technology has been formed by my twin concentration on data science and software engineering. I think it's possible to address complicated issues by combining machine learning techniques, deep learning models, and strong programming abilities.
                    </motion.p>
                    
                    <motion.p 
                        className="text-base font-light leading-relaxed tracking-wide text-center text-gray-200 sm:text-lg md:text-3xl/10 lg:text-lg"
                        initial={{ opacity: 0, y: 10 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        I'm now using my combined skill set as a Full Stack Developer to create software that evaluates and improves the usability of images from ancient books and manuscripts. This project makes use of machine learning, artificial intelligence and web development.
                    </motion.p>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default AboutMe;