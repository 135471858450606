import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logoImage from "../assets/yLogo.png";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [activeSection, setActiveSection] = useState("");
    const [scrolled, setScrolled] = useState(false);

    // Navigation links - Contact is not included in the navbar
    const navLinks = [
        { name: "About Me", href: "#about" },
        { name: "Skills", href: "#skills" },
        { name: "Projects", href: "#projects" },
        { name: "Certificates", href: "#certificates" },
    ];

    // Handle scroll events to update active section and navbar appearance
    useEffect(() => {
        const handleScroll = () => {
            // Update navbar appearance based on scroll position
            setScrolled(window.scrollY > 20);

            // Check if we're at the top of the page (header section)
            if (window.scrollY < 100) {
                setActiveSection("");
                return;
            }

            // Check if we're in the contact section
            const contactSection = document.getElementById("contact");
            if (contactSection) {
                const contactRect = contactSection.getBoundingClientRect();
                // If contact section is visible and close to the top
                if (contactRect.top < 200 && contactRect.bottom > 0) {
                    setActiveSection(""); // Clear active section when in contact
                    return;
                }
            }

            // Get all section elements
            const sectionElements = {};
            navLinks.forEach(link => {
                const sectionId = link.href.substring(1);
                const element = document.getElementById(sectionId);
                if (element) {
                    sectionElements[sectionId] = element.getBoundingClientRect();
                }
            });

            // Find the section closest to the top of the viewport
            let currentSection = "";
            let minDistance = Infinity;
            
            Object.entries(sectionElements).forEach(([id, rect]) => {
                // Calculate distance from the top of the viewport
                const distance = Math.abs(rect.top);
                
                // If this section is closer to the top than the current closest section
                if (distance < minDistance && rect.bottom > 0) {
                    minDistance = distance;
                    currentSection = id;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);
        // Trigger once on mount to set initial active section
        handleScroll();
        
        return () => window.removeEventListener("scroll", handleScroll);
    }, [navLinks]);

    // Toggle mobile menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Close mobile menu when a link is clicked
    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <>
            {/* Desktop Navbar */}
            <motion.nav
                className="fixed top-0 left-0 right-0 z-50 hidden w-full lg:block"
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div 
                    className={`flex items-center justify-between w-full px-8 py-4 mx-auto transition-all duration-300 ${
                        scrolled 
                            ? "bg-slate-900/90 backdrop-blur-md shadow-lg" 
                            : "bg-transparent"
                    }`}
                >
                    <div className="flex items-center">
                        <motion.img 
                            src={logoImage} 
                            alt="logo" 
                            className="object-contain w-10 h-10"
                            whileHover={{ rotate: 10, scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        />
                        <motion.span 
                            className="ml-3 text-xl font-bold text-white"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                        >
                            Yaya Momt
                        </motion.span>
                    </div>

                    <div className="flex items-center space-x-1">
                        {navLinks.map((link) => (
                            <motion.a
                                key={link.name}
                                href={link.href}
                                className={`relative px-4 py-2 text-sm font-medium transition-colors duration-300 rounded-lg ${
                                    activeSection === link.href.substring(1)
                                        ? "text-white"
                                        : "text-gray-400 hover:text-white"
                                }`}
                                whileHover={{ y: -2 }}
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                {link.name}
                                {activeSection === link.href.substring(1) && (
                                    <motion.div
                                        className="absolute bottom-0 left-0 right-0 h-0.5 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
                                        layoutId="navbar-indicator"
                                        initial={{ width: 0 }}
                                        animate={{ width: "50%" }}
                                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                                    />
                                )}
                            </motion.a>
                        ))}
                    </div>

                    <motion.a
                        href="mailto:yahyamomtaz@gmail.com"
                        className="relative flex items-center justify-center gap-2 px-5 py-2 overflow-hidden text-sm font-semibold text-black transition-shadow duration-300 rounded-full group focus:outline-none hover:shadow-lg"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        style={{
                            background: "linear-gradient(45deg, #95BA93, #CFAC2F)",
                        }}
                    >
                        <motion.div
                            className="absolute inset-0 bg-gradient-to-r from-white/20 to-transparent"
                            initial={{ x: "-100%" }}
                            animate={{ x: "100%" }}
                            transition={{ duration: 1.5, repeat: Infinity }}
                        />
                        <span className="relative z-10">Contact Me</span>
                    </motion.a>
                </div>
            </motion.nav>

            {/* Mobile Navbar */}
            <div className="fixed top-0 left-0 right-0 z-40 block w-full lg:hidden">
                {/* Mobile navbar header - always visible */}
                <div 
                    className={`fixed top-0 left-0 right-0 flex items-center justify-between w-full px-12 py-4 transition-all duration-300 z-50 ${
                        scrolled || isOpen
                            ? "bg-slate-900/90 backdrop-blur-md shadow-lg" 
                            : "bg-transparent"
                    }`}
                >
                    <div className="flex items-center">
                        <motion.img 
                            src={logoImage} 
                            alt="logo" 
                            className="object-contain w-24 my-8"
                            whileHover={{ rotate: 10 }}
                        />
                    </div>

                    {/* Mobile menu button - always visible */}
                    <button
                        className="relative flex flex-col items-center justify-center w-10 h-10 rounded-full bg-white/5"
                        onClick={toggleMenu}
                        aria-label={isOpen ? "Close menu" : "Open menu"}
                    >
                        <span 
                            className={`block w-10 h-1 bg-white transition-all duration-300 ${
                                isOpen ? "rotate-45 translate-y-2" : ""
                            }`}
                        />
                        <span 
                            className={`block w-10 h-1 bg-white my-1 transition-all duration-300 ${
                                isOpen ? "opacity-0" : ""
                            }`}
                        />
                        <span 
                            className={`block w-10 h-1 bg-white transition-all duration-300 ${
                                isOpen ? "-rotate-45 -translate-y-2" : ""
                            }`}
                        />
                    </button>
                </div>

                {/* Mobile menu */}
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            className="fixed inset-0 z-40 flex flex-col items-center justify-center w-full h-full pt-16 bg-slate-900/95 backdrop-blur-md"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="flex flex-col items-center space-y-6">
                                {navLinks.map((link, index) => (
                                    <motion.a
                                        key={link.name}
                                        href={link.href}
                                        className={`text-2xl font-medium ${
                                            activeSection === link.href.substring(1)
                                                ? "text-white"
                                                : "text-gray-400"
                                        }`}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ 
                                            opacity: 1, 
                                            y: 0,
                                            transition: { delay: 0.1 + index * 0.1 }
                                        }}
                                        exit={{ opacity: 0, y: 20 }}
                                        onClick={handleLinkClick}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        {link.name}
                                    </motion.a>
                                ))}
                                
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
}

export default Navbar;