import { motion, useInView } from "framer-motion";
import React, { useRef, useEffect } from "react";

export function SkillCard({ title, skills, icon }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  // Get appropriate emoji for the category with more flexible matching
  const getEmoji = () => {
    // If an icon is explicitly provided, use it
    if (icon) return icon;
    
    // More comprehensive mapping with lowercase keys for case-insensitive matching
    const emojis = {
      "development": "💻",
      "web": "🌐",
      "web development": "🌐",
      "frontend": "🎨",
      "front-end": "🎨",
      "backend": "⚙️",
      "back-end": "⚙️",
      "data": "📊",
      "data science": "📊",
      "database": "🗄️",
      "cloud": "☁️", 
      "aws": "☁️",
      "devops": "🔄",
      "mobile": "📱",
      "ios": "📱",
      "android": "📱",
      "ai": "🧠",
      "machine learning": "🧠",
      "ml": "🧠",
      "deep learning": "🧠",
      "analytics": "📈",
      "design": "🎨",
      "ui": "🎨",
      "ux": "🎨",
      "security": "🔒",
      "testing": "🧪",
      "languages": "🔤",
      "frameworks": "🧰",
      "tools": "🛠️",
      "default": "⚡️"
    };
    
    // Try to find a match (case-insensitive)
    const lowerTitle = title ? title.toLowerCase() : "";
    
    // First try exact match
    if (emojis[lowerTitle]) return emojis[lowerTitle];
    
    // Then try partial match
    for (const [key, emoji] of Object.entries(emojis)) {
      if (lowerTitle.includes(key) || key.includes(lowerTitle)) {
        return emoji;
      }
    }
    
    // Fallback to default
    return emojis.default;
  };

  // Get a color based on the category
  const getCategoryColor = () => {
    const colors = {
      "development": "blue",
      "web": "blue",
      "frontend": "indigo",
      "backend": "violet",
      "data": "purple",
      "cloud": "cyan",
      "devops": "green",
      "mobile": "orange",
      "ai": "pink",
      "machine learning": "pink",
      "analytics": "yellow",
      "design": "indigo",
      "security": "red",
      "testing": "amber",
      "languages": "emerald",
      "frameworks": "sky",
      "tools": "lime",
      "default": "blue"
    };
    
    const lowerTitle = title ? title.toLowerCase() : "";
    
    // Try to find a match
    if (colors[lowerTitle]) return colors[lowerTitle];
    
    // Try partial match
    for (const [key, color] of Object.entries(colors)) {
      if (lowerTitle.includes(key) || key.includes(lowerTitle)) {
        return color;
      }
    }
    
    return colors.default;
  };

  const categoryColor = getCategoryColor();
  const colorClasses = {
    blue: "bg-blue-500/20",
    indigo: "bg-indigo-500/20",
    violet: "bg-violet-500/20",
    purple: "bg-purple-500/20",
    pink: "bg-pink-500/20",
    red: "bg-red-500/20",
    orange: "bg-orange-500/20",
    amber: "bg-amber-500/20",
    yellow: "bg-yellow-500/20",
    lime: "bg-lime-500/20",
    green: "bg-green-500/20",
    emerald: "bg-emerald-500/20",
    teal: "bg-teal-500/20",
    cyan: "bg-cyan-500/20",
    sky: "bg-sky-500/20"
  };

  const bulletColors = {
    blue: "bg-blue-400",
    indigo: "bg-indigo-400",
    violet: "bg-violet-400",
    purple: "bg-purple-400",
    pink: "bg-pink-400",
    red: "bg-red-400",
    orange: "bg-orange-400",
    amber: "bg-amber-400",
    yellow: "bg-yellow-400",
    lime: "bg-lime-400",
    green: "bg-green-400",
    emerald: "bg-emerald-400",
    teal: "bg-teal-400",
    cyan: "bg-cyan-400",
    sky: "bg-sky-400"
  };

  return (
    <div ref={ref} className="h-full">
      <div className="flex items-center mb-4 sm:mb-6 lg:mb-4">
        <div className={`flex items-center justify-center w-8 h-8 mr-3 text-lg sm:text-3xl lg:text-lg rounded-lg ${colorClasses[categoryColor] || colorClasses.blue}`}>
          <span>{getEmoji()}</span>
        </div>
        <h3 className="text-4xl font-medium text-white sm:text-4xl lg:text-lg">{title}</h3>
      </div>
      
      <div className="space-y-6 sm:space-y-6 lg:space-y-2">
        {skills.map((skill, index) => (
          <motion.div 
            key={index}
            className="flex items-center group"
            initial={{ opacity: 0, x: -10 }}
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{ delay: 0.1 + index * 0.05 }}
          >
            <span className={`inline-block w-1 h-1 mr-2 sm:h-2 sm:w-2 rounded-full ${bulletColors[categoryColor] || bulletColors.blue}`} />
            <span className="text-4xl text-gray-300 transition-colors duration-200 sm:text-4xl lg:text-sm group-hover:text-white">
              {skill}
            </span>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default SkillCard;