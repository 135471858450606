import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import GridBackground from "./Grid";
import emojiIcon from "../assets/me.png";
import downloadButton from "../assets/arrowd.svg";
import cvPdf from "../utility/Yaya-Momt-CV.pdf";

function Header() {
  const [viewportHeight, setViewportHeight] = useState('100vh');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      setViewportHeight(`${window.innerHeight}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100,
      },
    },
  };

  return ( 
    <div
      className="relative flex items-center justify-center w-full max-w-full pb-16 mt-16 overflow-hidden sm:mt-16 md:mt-4 lg:mt-24 lg:pb-32"
      style={{ 
        height: viewportHeight,
        minHeight: viewportHeight,
        height: 'calc(var(--vh, 1vh) * 100)'
      }}
    >
      <GridBackground />

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="relative z-10 flex flex-col items-center justify-center w-full h-full max-w-full mx-auto space-y-4 sm:space-y-4 md:space-y-20 lg:space-y-4"
      >
        <motion.div
          className="relative"
          variants={itemVariants}
          whileHover={{ scale: 1.05 }}
          transition={{ type: "spring", stiffness: 200, damping: 10 }}
        >
          <motion.img 
            src={emojiIcon} 
            alt="emoji" 
            className="w-80 sm:w-[30rem] md:w-[35rem] lg:w-64 filter drop-shadow-2xl"
            animate={{
              rotate: [0, 5, -5, 0],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          <motion.div
            className="absolute rounded-full -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-xl"
            animate={{
              scale: [1, 1.1, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        </motion.div>

        <motion.h1 
          variants={itemVariants}
          className="px-4 text-xl font-bold tracking-wider text-center text-transparent sm:text-5xl md:text-8xl lg:text-7xl bg-clip-text bg-gradient-to-r from-white to-blue-200"
        >
          YAYA MOMT
        </motion.h1>

        <motion.p 
          variants={itemVariants}
          className="text-lg font-medium text-transparent sm:text-xl md:text-5xl lg:text-xl bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400"
        >
          Data Scientist 
        </motion.p>

        <motion.p 
          variants={itemVariants}
          className="w-2/3 px-4 mb-4 text-base font-light leading-relaxed tracking-wide text-center text-gray-300 sm:text-lg md:text-3xl/10 lg:text-lg"
        >
          I am a passionate learner and developer with a diverse skillset in web development, data science, cloud infrastructure, and artificial intelligence. Proficient in Python, R, Swift, Making APIs and Front End development.
        </motion.p>

        <motion.a 
          variants={itemVariants}
          className="relative flex items-center justify-center gap-2 sm:gap-3 px-6 sm:px-8 py-2.5 sm:py-3 md:py-6 lg:py-2 overflow-hidden text-base sm:text-lg font-semibold text-white rounded-full group focus:outline-none hover:shadow-lg transition-shadow duration-300 w-fit sm:w-fit md:w-2/3 lg:w-fit"
          href={cvPdf}
          download="Yaya-Momt-CV.pdf"
          target="_blank"
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          whileHover={{ scale: 1.02 }}
          style={{
            background: "linear-gradient(45deg, #95BA93, #CFAC2F)",
          }}
        >
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-white/20 to-transparent"
            initial={{ x: "-100%" }}
            animate={{ x: isHovered ? "100%" : "-100%" }}
            transition={{ duration: 0.5 }}
          />
          <motion.img 
            src={downloadButton} 
            className="h-4 transition-transform sm:h-5 md:h-10 lg:h-6 group-hover:-rotate-90" 
            alt="Download Arrow" 
          />
          <span className="relative z-10 text-sm text-black sm:text-lg md:text-4xl lg:text-lg">Download my CV</span>
        </motion.a>
      </motion.div>
    </div>
  );
}

export default Header;
